<template>
  <page>
    <template #navigation>
      <media-heading :search="search" @search="onInputSearch" @upload="onImagesUpload" />
    </template>
    <media-grid :search="search" pick="details" />
  </page>
</template>

<script>
import MediaHeading from '@/components/media/MediaHeading'
import MediaGrid from '@/components/media/MediaGrid'

import { getNiceSize } from '@/services'

export default {
  components: { MediaHeading, MediaGrid },
  data() {
    return {
      search: '',
      filter: 'all',
      showPack: false,
      showSend: false,
      packed: []
    }
  },
  computed: {
    packedIds() {
      return this.packed.map(({ _id }) => _id)
    },
    packedImages() {
      return this.packed.map(({ _id, original }) => ({
        id: _id,
        label: original
      }))
    },
    totalSize() {
      if (!this.packed.length) {
        return { size: 0, safe: true }
      }

      const size = this.packed.reduce(
        (sum, item) => sum + parseInt(item.size),
        0
      )
      const tenMegs = 10486760
      const niceSize = getNiceSize({ size })
      return {
        safe: size < tenMegs,
        value: `${niceSize.value}${niceSize.unit}`
      }
    }
  },
  methods: {
    onInputSearch(value) {
      this.search = value
    },
    onInputFilter(value) {
      this.filter = value
    },
    async onImagesUpload(data) {
      const promises = data.map(
        (upload) => async () => this['images/create'](upload)
      )
      await Promise.all(promises.map((p) => p()))
    }
  }
}
</script>
